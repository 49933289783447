/* **** Overriding stepper.css **** */
#create-mass-intention .pearl-number {
  font-weight: bold;
  line-height: 33px;
}
/* ******************************** */

#create-mass-intention .form-check-input, #create-mass-intention .form-check-label {
  cursor: pointer;
}

.mass-intention-background-blue {
  background-color: #3771c9 !important; /* #007bff is the Boostrap "primary" colour. */
  color: white;
}

.mass-intention-background-light-blue {
  background-color: #e8f2ff;
}

.mass-intention-border-blue {
  border: 1px solid #3771c9 !important; /* #007bff is the Boostrap "primary" colour. */
}

.mass-intention-background-green {
  background-color: #3fb78d; /* #28a745 is the Bootstrap "success" colour. */
  color: white;
}

/* **** Sets the border colour to indicate that a box-shaped UI element has been selected **** */
/* The colours mimic that of a selected textarea */
.mass-intention-border-selected {
  position: relative !important;
  border: 1px solid #7fbdff !important;
  border-radius: 4px !important;
}

.mass-intention-border-selected:before {
  /* Adapted from https://css-tricks.com/snippets/css/multiple-borders/#aa-using-pseudo-elements */
  content: " " !important;
  position: absolute !important;
  z-index: -1 !important;
  top: -4px !important;
  left: -4px !important;
  right: -4px !important;
  bottom: -4px !important;
  border: 4px solid #c7deff !important;
  border-radius: 6px !important;
}
/* ********************************************************************************** */

/* Sets the text colour to indicate that its UI element has been selected. */
.mass-intention-text-selected {
  color: #7fbdff;
}

.mass-intention-select-type-of-intention .form-check-label {
  padding-left: 15px;
  padding-bottom: 2px;
}

.mass-intention-select-type-of-intention .form-check-input {
  position: relative !important;
  vertical-align: text-top;
  transform: scale(1.4);
}

#mass-intention-date-and-time-selectors .card-body {
  padding: 6px 12px !important;
}

/* **** Overriding react-calandar **** */
.mass-intention-react-calendar .react-calendar {
  width: auto;
  max-width: 350px;
  border: 1px solid #ffffff !important;
}

.mass-intention-react-calendar .react-calendar__tile--now,
.mass-intention-react-calendar .react-calendar__tile--active,
.mass-intention-react-calendar .react-calendar__navigation button:enabled:hover,
.mass-intention-react-calendar .react-calendar__tile:enabled:hover {
  border-radius: 6px !important;
}

.mass-intention-react-calendar .react-calendar__navigation {
  margin-bottom: 0px !important;
}

.mass-intention-react-calendar .react-calendar__year-view__months,
.mass-intention-react-calendar .react-calendar__decade-view__years,
.mass-intention-react-calendar .react-calendar__century-view__decades {
  margin-top: 8px;
}

.mass-intention-react-calendar .react-calendar__tile--now {
  background: #e6e6e6 !important;
}
.mass-intention-react-calendar .react-calendar__tile--active,
.mass-intention-react-calendar .react-calendar__tile--hasActive {
  background: #3771c9 !important;
  color: white;
}

.mass-intention-react-calendar abbr[title] {
  /* Overriding the weekday titles */
  text-decoration: none !important;
  cursor: default !important;
  color: #3771c9;
  font-weight: bold;
}
/* *********************************** */

.mass-intention-time-picker > .time-picker {
  max-width: 350px;
}

.mass-intention-time-picker > .time-picker .hover:hover {
  background-color: #e6e6e6;
  border-radius: 6px;
}

.mass-intention-time-picker > .time-picker .selected {
  color: white;
  background-color: #3771c9;
  border-radius: 6px;
}

.mass-intention-text-underline {
  text-decoration: underline;
}

.mass-intention-rounded-corner {
  border-radius: 12px;
}

.mass-intention-help-circle-number {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: inline-block;
  text-align: right;
  background-color: #3771c9;
}

.mass-intention-help-circle-number-text {
  color: white;
  position: absolute;
  top: 8px;
  font-weight: bold
}

#mass-intention-information-card .information-card-header {
  height: 45px;
  line-height: 1.4 !important;
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
}

#mass-intention-information-card .border-blue {
  border: 2px solid #3771c9 !important; /* #007bff is the Boostrap "primary" colour. */
}

#mass-intention-information-card .border-green {
  border: 2px solid #3fb78d !important; /* #28a745 is the Bootstrap "success" colour. */
}

.mass-intention-text-light-bold {
  font-weight: 500;
}

.create-mass-intention-label {
  padding-top: 2px;
}

.mass-intention-help-text-blue {
  color: #3771c9;
}

.add-mass-intention-modal {
  min-width: 60%!important;
}