@import url(https://fonts.googleapis.com/css2?family=Inter+Tight:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
.heart {
  cursor: pointer;
  height: 50px;
  width: 50px;
  background-image: url("https://firebasestorage.googleapis.com/v0/b/mycatholicsg-prod01.appspot.com/o/cdn%2Flike%2Fweb_heart_animation.png?alt=media&token=54d2f557-9fb0-4560-9e7e-8cdd65a6f44e");
  background-position: left;
  background-repeat: no-repeat;
  background-size: 2900%;
}

.heart {
  animation: heart-burst 1s infinity;
}

.heartclick {
  background-position: right;
  color: #d93d3f;
}

.adjustheart {
  margin-top: -12px;
  margin-left: -15px;
}
.adjustleft {
  margin-left: -10px;
}
.heart:hover {
  background-position: right;
}
.is_animating {
  animation: heart-burst 2s steps(28) 3;
}
.likediv:hover {
  color: #d93d3f;
}

@keyframes heart-burst {
  from {
    background-position: left;
  }
  to {
    background-position: right;
  }
}

.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 83vh; /* Adjust as needed */
  }
  
  .spinner {
    border: 4px solid #d93d3f; /* Red color with some transparency */
    border-top: 4px solid #FFFFFF; /* White color */
    border-radius: 50%;
    width: 32px;
    height: 32px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
.floating-input-field {
  display: flex;
  width: 100%;
  position: relative;
}

.floating-input-field--full {
  width: 100%;
}
.floating-input-field > span, input, textarea, select{
  transition: all 0.2s;
  touch-action: manipulation;
}
.floating-input-field > input:focus,
.floating-input-field > textarea:focus {
  outline: 0;
}

.floating-input-field > input:placeholder-shown + span,
.floating-input-field > textarea:placeholder-shown + span {
  opacity: 0;
  position: absolute;
  top: -15px;
  padding: 0 5px;
  color: transparent;
  cursor: text;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transform: translate(0, 0.5rem) scale(0.75);
}

.floating-input-field > input:focus::placeholder,
.floating-input-field > textarea:focus::placeholder{
  color: transparent;
}

.floating-input-field > input:not(:placeholder-shown) + span,
.floating-input-field > input:focus + span,
.floating-input-field > textarea:not(:placeholder-shown) + span,
.floating-input-field > textarea:focus + span {
  opacity: 1;
  position: absolute;
  top: -20px;
  padding: 0 5px;
  color: #828282;
  transform: translate(0, 0.5rem) scale(0.75);
  background-color: #FFFFFF;
  cursor: pointer;
}

.floating-input-field input {
  width: 100%;
  height: 55px;
  padding-left: 15px;
  border: 2px solid #D0D3D7;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.floating-input-field input:focus {
  outline: none;
}

.floating-input-field input::placeholder {
  font-size: 14px;
  color: #828282;
}
.floating-input-field span,
.floating-input-field > span, input, textarea, select,
.floating-input-field > .port-setup-jobId__container > span, input {
  transition: all 0.2s;
  touch-action: manipulation;
}
.floating-input-field span {
  position: absolute;
  top: -20px;
  padding: 0 5px;
  color: #828282;
  cursor: text;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: #FFFFFF;
  font-size:1rem;
  transform: translate(0, 0.5rem) scale(0.75);
}
/* Event Admin Dashboard CSS */

.com-event__select__value-container {
    min-width: 100px;
}
  
.com-event__btn {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  font-size: 16px;
  height: 50px;
  width: 150px;
  font-weight: 700;
  color: #828282;
  background-color: #e5e5e5;
  border-radius: 5px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.com-event__btn--blue-muted {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  font-size: 16px;
  height: 50px;
  width: 150px;
  font-weight: 700;
  color: #ffffff;
  background-color: #8cbeff;
  border-radius: 5px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.com-event__btn--blue-unselected {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  font-size: 16px;
  height: 50px;
  width: 150px;
  font-weight: 700;
  color: #244a85;
  background-color: #d7e3f4;
  border-radius: 5px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.com-event__btn--deep-blue {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  font-size: 16px;
  height: 50px;
  width: 150px;
  font-weight: 700;
  color: #ffffff;
  background-color: #0347ad;
  border-radius: 5px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.com-event-upload__container {
  height: 200px;
  border-radius: 5px;
  border: 2px solid #d0d3d7;
  overflow: hidden;
}

.com-event-image__container {
  height: 100%;
  width: 100%;
  object-fit: cover;
  overflow: hidden;
}

.com-event__img {
  width: 100%;
}

/* Event Cards */
.com-event-card__container {
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  height: 260px;
  width: 250px;
  margin-bottom: 40px;
  border-radius: 5px;
  background-color: #f6f7f8;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer;
}

.com-event-card__container:hover {
  background-color: #eaebec;
}

.com-event-card__container--selected {
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  height: 260px;
  width: 250px;
  margin-bottom: 40px;
  border-radius: 5px;
  background-color: #f6f7f8;
  border: 2px solid #0347ad;
  box-shadow: 0px 2px 2px #0347ad;
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer;
}

/* Detail Title */

.com-detail__circle {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: center;
  text-align: center;
  font-size: 24px;
  height: 50px;
  width: 50px;
  border-radius: 25px;
  color: #ffffff;
  background-color: #0347ad;
}

.com-event__textarea {
  height: 200px;
  width: 100%;
  padding: 15px;
  border: 2px solid #d0d3d7;
  border-radius: 4px;
}

.com-event__textarea:focus {
  box-shadow: 0 0 2px 1px #0347ad;
}

.com-event-desc-example__content {
  background-color: #ebf3fd;
  padding: 15px;
  border-radius: 4px;
}

.com-event__checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  height: 24px;
  width: 24px;
  cursor: pointer;
  border: 2px solid #d0d3d7;
}

/* Detail DatePicker */
.com-event__date-input {
  height: 55px;
  padding-left: 10px;
  border: 2px solid #d0d3d7;
  border-radius: 4px;
}

.com-event__date-input:focus-within {
  box-shadow: 0 0 2px 1px #0347ad;
}

.com-event__date-wrapper input {
  border: none !important;
  height: 50px !important;
  padding-left: 10px !important;
  cursor: pointer;
}

/* Detail Select */
.com-event__select-container {
  width: 100%;
  height: 100%;
}

.com-event__select__control {
  max-height: 55px;
  border-color: none !important;
  box-shadow: none !important;
}

.com-event__select__value-container {
  padding-left: 15px !important;
}

.com-event__select__input-container {
  max-height: 50px;
}

/* Detail Select Multi */
.com-event__select-multi-container {
  width: 100%;
  height: 100%;
}

.com-event__select-multi__control:hover {
  border-color: #d0d3d7 !important;
}

.com-event__select-multi__control--is-focused {
  border-color: #d0d3d7 !important;
  box-shadow: 0 0 2px 1px #0347ad !important;
  outline: none !important;
}

.com-event__select-multi__value-container {
  padding: 15px 0 0 15px !important;
}

.com-event__select-multi__input-container {
  max-height: 50px;
}

/* Admin Event Success */

.com-event-success__container {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}

.com-event-success__link {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: #3479f6;
  cursor: default;
}

.com-event-success__link:hover {
  text-decoration: underline;
}

/* Admin Event Table */
.com-event-table__btn {
  color: #7c7c80;
  cursor: pointer;
}

.com-event-table__btn:hover {
  color: #0347ad;
}

.com-event__date-picker-day {
  outline: none !important;
}

.com-event__date-picker-day.react-datepicker__day--selected {
  background-color: #0347ad;
}

.com-event__date-picker-day.react-datepicker__day--selected:hover {
  background-color: #3479f6;
}

.com-event__date-picker-day.react-datepicker__day--in-selecting-range {
  background-color: #3479f6 !important;
}

.com-event__date-picker-day.react-datepicker__day--in-range {
  background-color: #0347ad;
}

.com-event__date-picker-day.react-datepicker__day--in-range:hover {
  background-color: #3479f6;
}

.com-event__date-picker-day.react-datepicker__day--keyboard-selected {
  background-color: #0347ad;
}

.com-event__date-picker-day.react-datepicker__day--keyboard-selected:hover {
  background-color: #3479f6;
}

.com-event__date-picker__popper {
  z-index: 3 !important;
}

.com-event__date-picker__popper
  > div
  > .react-datepicker
  > .react-datepicker__triangle {
  left: -90px !important;
}

.com-event-form__date-picker__popper
  > div
  > .react-datepicker
  > .react-datepicker__triangle {
  left: -10px !important;
}

.com-event__date-icon {
  margin-right: 10px;
}

.com-event__input:focus {
  box-shadow: 0 0 2px 1px #0347ad;
}

.com-event__input:focus > span {
  color: #3479f6;
}

.com-event__input:focus-within > span {
  color: #3479f6;
}

.com-event__input-wrapper:focus-within > span {
  color: #3479f6 !important;
}

@media (max-width: 1200px) {
  .com-event-volunteer__modal-dialog {
    max-width: 95vw !important;
  }
}

.com-event-volunteer__confirm-backdrop {
  z-index: 1060 !important;
}
  
.programme-select > .css-109ot1r-control,
.programme-select > .css-1397urt-control,
.programme-select > .css-1057fy8-control,
.programme-select > .css-1wzyqe9-control,
.programme-select > .css-1l9eo98-control,
.programme-select > .css-6qy2ip-control {
  height: calc(1.5em + 1rem + 2px);
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.programme-select > .css-109ot1r-control > .css-1fdsijx-ValueContainer,
.programme-select > .css-1397urt-control > .css-1fdsijx-ValueContainer,
.programme-select > .css-1057fy8-control > .css-1fdsijx-ValueContainer,
.programme-select > .css-1wzyqe9-control > .css-1fdsijx-ValueContainer,
.programme-select > .css-1l9eo98-control > .css-1fdsijx-ValueContainer,
.programme-select > .css-6qy2ip-control > .css-1fdsijx-ValueContainer {
  padding-left: 1rem;
  padding-right: 1rem;
}

.programme-select.select-error > .css-109ot1r-control:hover,
.programme-select.select-error > .css-1397urt-control:hover,
.programme-select.select-error > .css-1057fy8-control:hover,
.programme-select.select-error > .css-1wzyqe9-control:hover,
.programme-select.select-error > .css-1l9eo98-control:hover,
.programme-select.select-error > .css-6qy2ip-control:hover {
  border-color: #dc3545;
}

.programme-select-error-icon {
  position: absolute;
  right: 54px;
  margin-top: 13px;
  font-size: 21px !important;
}

.programme-select-expand-icon {
  position: absolute;
  right: 30px;
  margin-top: 14px;
  font-size: 19px !important;
}

.graph-tooltip {
  background-color: #f8f9fa !important;
  border: 1px solid rgb(108, 117, 125);
}

.overview-statistic-row-contaier {
  .card {
    height: 100%;
  }

  .card-body {
    background-color: rgba(0, 0, 0, 0.03);
    margin: 0px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

#waiverTooltip>.tooltip-inner {
  color: black;
  background-color: white;
  border: 2px solid #FFC107;
}

#waiverTooltip>.arrow::before {
  border-top-color: #FFC107;
}

/* Table */
.table__wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;

  &.sticky {

    .table__header-container,
    .footer {
      position: -webkit-sticky;
      position: sticky;
      z-index: 1;
    }

    .table__header {
      top: 0;
    }

    .footer {
      bottom: 0;
    }

    .body {
      position: relative;
      z-index: 0;
    }

    [data-sticky-td] {
      position: -webkit-sticky !important;
      position: sticky !important;
    }
  }
}

.table__container {
  display: flex;
  flex-direction: column;
  height: 60vh;
  background-color: #ffffff;
  width: 100%;
  overflow-x: scroll;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.25);
}

.table__header-container {
  display: flex;
  height: 50px;
  align-items: center;
  border-radius: 5px 5px 0 0;
  color: #7c7c80;
  background-color: #ffffff;

  position: -webkit-sticky;

  position: sticky;
  top: 0;
  z-index: 2;
  /* width: 100%; */
}

.table__header {
  display: flex;
  height: 100%;
  padding: 10px 0px 10px 20px;
  -webkit-user-select: none;
          user-select: none;
  position: relative;
  justify-content: space-between;
  border-bottom: 1px solid #e5e5e5;
  background-color: #ffffff;
}

.table__header:last-child>.resizer {
  display: none;
}

.table__header-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.resizer {
  right: 0;
  width: 4px;
  height: 35px;
  margin-right: 8px;
  position: absolute;
  touch-action: none;
}

.resizer:hover {
  background: #7c7c80;
}

.resizer.isResizing {
  background: #9B1ACC;
  cursor: none;
}

.table__body-no-results {
  display: flex;
  justify-content: center;
  margin-top: 100px;
  color: #7c7c80;
}

.table__data {
  display: flex;
  position: relative;
  font-size: 14px;
  height: 50px;
  background-color: #ffffff;
  align-items: center;
  padding: 0 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-bottom: 1px solid #e5e5e5;
}

.table__data:last-child {
  display: flex;
  position: relative;
  font-size: 14px;
  height: 50px;
  background-color: #ffffff;
  align-items: center;
  padding: 0 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.table__pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  padding: 0 10px;
  -webkit-user-select: none;
          user-select: none;
}

.table__pagination-direction {
  display: flex;
  width: 80px;
  color: #7c7c80;
  justify-content: space-between;
}

.table__pagination-btn--disabled {
  color: #c7c7c7;
}

.table__pagination-count {
  margin: 0 40px;
  color: #7c7c80;
}

.table__pagination-select {
  outline: none;
  border: none;
  background: none;
  margin-left: 10px;
  color: #7c7c80;
}

.table__pagination-size {
  display: flex;
  color: #7c7c80;
}
.pearls {
  /* display: block; */
  margin: 0 0 22px;
}
.pearl {
  position: relative;
  padding: 0;
  margin: 0;
  text-align: center;
}
.pearl:after,
.pearl:before {
  position: absolute;
  top: 18px;
  z-index: 0;
  width: 50%;
  height: 4px;
  content: "";
  background-color: #eee;
}
.pearl:before {
  left: 0;
}
.pearl:after {
  right: 0;
}
.pearl:first-child:before,
.pearl:last-child:after {
  display: none !important;
}
.pearl-icon,
.pearl-number {
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 36px;
  height: 36px;
  line-height: 32px;
  color: #fff;
  text-align: center;
  background: #bdbdbd;
  border: 2px solid #bdbdbd;
  border-radius: 50%;
}
.pearl-number {
  font-size: 18px;
}
.pearl-icon {
  font-size: 18px;
}
.pearl-title {
  display: block;
  margin-top: 0.5em;
  margin-bottom: 0;
  overflow: hidden;
  font-size: 16px;
  color: #616161;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.pearl.current:after,
.pearl.current:before {
  background-color: #3d811e;
}
.pearl.current .pearl-icon,
.pearl.current .pearl-number {
  color: #3d811e;
  background-color: #fff;
  border-color: #3d811e;
  transform: scale(1.3);
}
.pearl.disabled {
  pointer-events: none;
  cursor: auto;
}
.pearl.disabled:after,
.pearl.disabled:before {
  background-color: #eee;
}
.pearl.disabled .pearl-icon,
.pearl.disabled .pearl-number {
  color: #fff;
  background-color: #bdbdbd;
  border-color: #bdbdbd;
}
.pearl.error:before {
  background-color: #3f51b5;
}
.pearl.error:after {
  background-color: #eee;
}
.pearl.error .pearl-icon,
.pearl.error .pearl-number {
  color: #f44336;
  background-color: #fff;
  border-color: #f44336;
}
.pearl.done:after,
.pearl.done:before {
  background-color: #3d811e;
}
.pearl.done .pearl-icon,
.pearl.done .pearl-number {
  color: #fff;
  background-color: #3d811e;
  border-color: #3d811e;
}
.pearls-lg .pearl:after,
.pearls-lg .pearl:before {
  top: 20px;
}
.pearls-lg .pearl-title {
  font-size: 18px;
}
.pearls-lg .pearl-icon,
.pearls-lg .pearl-number {
  width: 40px;
  height: 40px;
  line-height: 36px;
}
.pearls-lg .pearl-icon {
  font-size: 20px;
}
.pearls-lg .pearl-number {
  font-size: 20px;
}
.pearls-sm .pearl:after,
.pearls-sm .pearl:before {
  top: 16px;
}
.pearls-sm .pearl-title {
  font-size: 14px;
}
.pearls-sm .pearl-icon,
.pearls-sm .pearl-number {
  width: 32px;
  height: 32px;
  line-height: 28px;
}
.pearls-sm .pearl-number {
  font-size: 16px;
}
.pearls-sm .pearl-icon {
  font-size: 14px;
}
.pearls-xs .pearl:after,
.pearls-xs .pearl:before {
  top: 12px;
  height: 2px;
}
.pearls-xs .pearl-title {
  font-size: 12px;
}
.pearls-xs .pearl-icon,
.pearls-xs .pearl-number {
  width: 24px;
  height: 24px;
  line-height: 20px;
}
.pearls-xs .pearl-number {
  font-size: 12px;
}
.pearls-xs .pearl-icon {
  font-size: 12px;
}

.adjustcheckicon {
  margin-top: -2px;
}

/* **** Overriding stepper.css **** */
#create-mass-intention .pearl-number {
  font-weight: bold;
  line-height: 33px;
}
/* ******************************** */

#create-mass-intention .form-check-input, #create-mass-intention .form-check-label {
  cursor: pointer;
}

.mass-intention-background-blue {
  background-color: #3771c9 !important; /* #007bff is the Boostrap "primary" colour. */
  color: white;
}

.mass-intention-background-light-blue {
  background-color: #e8f2ff;
}

.mass-intention-border-blue {
  border: 1px solid #3771c9 !important; /* #007bff is the Boostrap "primary" colour. */
}

.mass-intention-background-green {
  background-color: #3fb78d; /* #28a745 is the Bootstrap "success" colour. */
  color: white;
}

/* **** Sets the border colour to indicate that a box-shaped UI element has been selected **** */
/* The colours mimic that of a selected textarea */
.mass-intention-border-selected {
  position: relative !important;
  border: 1px solid #7fbdff !important;
  border-radius: 4px !important;
}

.mass-intention-border-selected:before {
  /* Adapted from https://css-tricks.com/snippets/css/multiple-borders/#aa-using-pseudo-elements */
  content: " " !important;
  position: absolute !important;
  z-index: -1 !important;
  top: -4px !important;
  left: -4px !important;
  right: -4px !important;
  bottom: -4px !important;
  border: 4px solid #c7deff !important;
  border-radius: 6px !important;
}
/* ********************************************************************************** */

/* Sets the text colour to indicate that its UI element has been selected. */
.mass-intention-text-selected {
  color: #7fbdff;
}

.mass-intention-select-type-of-intention .form-check-label {
  padding-left: 15px;
  padding-bottom: 2px;
}

.mass-intention-select-type-of-intention .form-check-input {
  position: relative !important;
  vertical-align: text-top;
  transform: scale(1.4);
}

#mass-intention-date-and-time-selectors .card-body {
  padding: 6px 12px !important;
}

/* **** Overriding react-calandar **** */
.mass-intention-react-calendar .react-calendar {
  width: auto;
  max-width: 350px;
  border: 1px solid #ffffff !important;
}

.mass-intention-react-calendar .react-calendar__tile--now,
.mass-intention-react-calendar .react-calendar__tile--active,
.mass-intention-react-calendar .react-calendar__navigation button:enabled:hover,
.mass-intention-react-calendar .react-calendar__tile:enabled:hover {
  border-radius: 6px !important;
}

.mass-intention-react-calendar .react-calendar__navigation {
  margin-bottom: 0px !important;
}

.mass-intention-react-calendar .react-calendar__year-view__months,
.mass-intention-react-calendar .react-calendar__decade-view__years,
.mass-intention-react-calendar .react-calendar__century-view__decades {
  margin-top: 8px;
}

.mass-intention-react-calendar .react-calendar__tile--now {
  background: #e6e6e6 !important;
}
.mass-intention-react-calendar .react-calendar__tile--active,
.mass-intention-react-calendar .react-calendar__tile--hasActive {
  background: #3771c9 !important;
  color: white;
}

.mass-intention-react-calendar abbr[title] {
  /* Overriding the weekday titles */
  text-decoration: none !important;
  cursor: default !important;
  color: #3771c9;
  font-weight: bold;
}
/* *********************************** */

.mass-intention-time-picker > .time-picker {
  max-width: 350px;
}

.mass-intention-time-picker > .time-picker .hover:hover {
  background-color: #e6e6e6;
  border-radius: 6px;
}

.mass-intention-time-picker > .time-picker .selected {
  color: white;
  background-color: #3771c9;
  border-radius: 6px;
}

.mass-intention-text-underline {
  text-decoration: underline;
}

.mass-intention-rounded-corner {
  border-radius: 12px;
}

.mass-intention-help-circle-number {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: inline-block;
  text-align: right;
  background-color: #3771c9;
}

.mass-intention-help-circle-number-text {
  color: white;
  position: absolute;
  top: 8px;
  font-weight: bold
}

#mass-intention-information-card .information-card-header {
  height: 45px;
  line-height: 1.4 !important;
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
}

#mass-intention-information-card .border-blue {
  border: 2px solid #3771c9 !important; /* #007bff is the Boostrap "primary" colour. */
}

#mass-intention-information-card .border-green {
  border: 2px solid #3fb78d !important; /* #28a745 is the Bootstrap "success" colour. */
}

.mass-intention-text-light-bold {
  font-weight: 500;
}

.create-mass-intention-label {
  padding-top: 2px;
}

.mass-intention-help-text-blue {
  color: #3771c9;
}

.add-mass-intention-modal {
  min-width: 60%!important;
}
/* Finance Dashboard Top Nav */

.finance-topnav__container {
  display: flex;
  width: 100%;
  padding: 8px 16px;
  justify-content: space-between !important;
  background-color: #ffffff;
  height: 60px;
  border-bottom: 1px solid #efefef;
  box-shadow: 1px 1px 4px #dfdfdf;
  cursor: default;
}

.finance-topnav__section-container {
  display: flex;
  width: 33%;
}

.finance-topnav__section--center {
  justify-content: center;
}

.finance-topnav__section--right {
  justify-content: flex-end;
}

.finance-topnav__logo {
  height: 30px;
  width: 30px;
  align-self: center;
  margin-right: 5px;
}

.finance-topnav__logo-text {
  margin-top: 3px;
}
/* Finance Dashboard Side Nav */
.finance-sidenav__container {
  display: flex;
  flex-direction: column;
  padding-top: 100px;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 80px;
  height: 100%;
  background-color: #ffffff;
  z-index: 2;
}

.finance-sidenav__section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  margin-bottom: 30px;
  cursor: default;
}

.finance-sidenav__section--selected {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
  margin-bottom: 30px;
  cursor: default;
  color: #9B1ACC;
}

.finance-sidenv__section-text {
  font-size: 12px;
}

.finance-sidenav__section:hover {
  color: #ffffff !important;
  background-color: #E1BAF0;
}

.finance-sidenav__section:hover > .finance-sidenav__icon {
  color: #ffffff !important;
}

.finance-sidenav__icon {
  font-size: 36px !important;
  color: #808080;
}

.finance-sidenav__section--selected > .finance-sidenav__icon {
  color: #9B1ACC;
}
/* Finance Transaction Page */

.finance-transaction__container {
  display: flex;
  flex-direction: column;
  padding: 40px;
  background-color: #f6f8ff;
}

.finance-transaction__header {
  font-size: 28px;
  color: #9b1acc;
  margin-bottom: 20px;
}

.finance-transaction__table-controls {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  color: #7c7c80;
}

.finance-transaction__table-controls-section {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.finance-transaction__date-icon {
  margin-right: 10px;
}

.finance-transaction__date-picker__popper {
  z-index: 3 !important;
}

.finance-transaction__date-picker {
  display: flex;
  margin-right: 20px;
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer;
}

.finance-transaction__date-picker-day {
  outline: none !important;
}

.finance-transaction__date-picker-day.react-datepicker__day--selected {
  background-color: #9b1acc;
}

.finance-transaction__date-picker-day.react-datepicker__day--selected:hover {
  background-color: #e1baf0;
}

.finance-transaction__date-picker-day.react-datepicker__day--in-selecting-range {
  background-color: #e1baf0 !important;
}

.finance-transaction__date-picker-day.react-datepicker__day--in-range {
  background-color: #9b1acc;
}

.finance-transaction__date-picker-day.react-datepicker__day--in-range:hover {
  background-color: #e1baf0;
}

.finance-transaction__date-picker-day.react-datepicker__day--keyboard-selected {
  background-color: #9b1acc;
}

.finance-transaction__date-picker-day.react-datepicker__day--keyboard-selected:hover {
  background-color: #e1baf0;
}

.finance-transaction__date-live {
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer;
}

.finance-transaction__date-live--active {
  color: #fc7c80;
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer;
}

.finance-transaction__search-input {
  padding: 0 10px !important;
  color: #7c7c80;
}

.finance-transaction__search-input:focus {
  outline: none;
}

.finance-transaction__dropdown-item {
  color: #7c7c80 !important;
  text-decoration: none !important;
}

.finance-transaction__dropdown-item:hover {
  background-color: #e1baf0 !important;
  color: #ffffff !important;
}

.finance-transaction__download--hidden {
  display: none;
}

.finance-transaction__filter {
  display: none;
}

.finance-transaction__table-body {
  display: flex;
  margin-top: 20px;
}

.create-qr-modal__dialog {
  width: 90% !important;
  max-width: none!important;
}

.create-qr-modal__container {
  padding: 30px !important;
  min-height: 650px;
}

.create-qr-modal-header {
  font-size: 28px;
  color: #9B1ACC;
  margin-bottom: 15px;
}

.create-qr-modal-status__container {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.create-qr-modal-status-bar__container {
  display: flex;
  justify-content: center;
  font-size: 16px;
  -webkit-user-select: none;
          user-select: none;
  cursor: default;
}

.create-qr-modal-status__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  border-radius: 15px;
  margin: 10px 0 5px 0;
  background-color: #7c7c80;
  color: #ffffff;
}

.create-qr-modal-status__icon--selected {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  border-radius: 15px;
  margin: 10px 0 5px 0;
  background-color: #9B1ACC;
  color: #ffffff;
}

.create-qr-modal-status__header {
  display: flex;
  width: 33%;
  color: #7c7c80;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.create-qr-modal-status__header--selected {
  display: flex;
  width: 33%;
  color: #9B1ACC;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.create-qr-modal-status__divider {
  width: 31%;
  height: 2px;
  margin-top: 25px;
  background-color: #7c7c80;
}

.create-qr-modal-status__divider--selected {
  width: 31%;
  height: 2px;
  margin-top: 25px;
  background-color: #9B1ACC;
}

.create-qr-modal__form-details {
  display: flex;
  justify-content: space-between;
}

.create-qr-modal__side-section {
  display: flex;
  flex-direction: column;
  width: 45%;
}

.create-qr-modal__side-section--border {
  display: flex;
  flex-direction: column;
  width: 45%;
  border-right: 1px solid #7c7c80;
}

.create-qr-modal__side-section--padding {
  display: flex;
  flex-direction: column;
  width: 45%;
  padding: 0 0 0 10px;
}

.create-qr-modal__section {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 15px;
}

.create-qr-modal__section--border-row {
  display: flex;
  width: 100%;
  padding: 10px;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 15px;
  border: 1px solid #7c7c80;
}

.create-qr-modal__confirmation {
  display: flex;
  width: 100%;
  padding: 10px;
  flex-direction: row;
  margin-bottom: 15px;
  border: 1px solid #7c7c80;
}

.create-qr-modal__confirmation--row-space {
  height: 40px;
}

.create-qr-modal__confirmation--row-space > td {
  padding-right: 20px;
}

.create-qr-modal__section--margin {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 0 0 15px 15px;
}

.create-qr-modal__sub-section {
  display: flex;
}

@media (max-width: 800px) {
  .create-qr-modal__sub-section {
    display: flex;
    flex-wrap: wrap;
}
}

.create-qr-modal__section-title {
  margin-bottom: 5px;
  font-size: 18px;
  color: #7c7c80;
  cursor: default;
  -webkit-user-select: none;
          user-select: none;
}

.create-qr-modal__section-sub-title {
  margin-bottom: 5px;
  font-size: 16px;
  color: #7c7c80;
  cursor: default;
  -webkit-user-select: none;
          user-select: none;
}

.create-qr-modal__input-box {
  width: 100%;
  height: 30px;
  padding: 0 10px;
  color: #7c7c80;
  outline: none;
}

.create-qr-modal__textarea {
  width: 100%;
  height: 150px;
  outline: none;
  resize: none;
  padding: 10px;
  color: #7c7c80;
}

.create-qr-modal__radio {
  display: flex;
  height: 30px;
  align-items: center;
}

.create-qr-modal__radio-input {
  margin-right: 10px;
}

.create-qr-modal__radio-text {
  margin-right: 25px;
  cursor: default;
  -webkit-user-select: none;
          user-select: none;
}

.create-qr-modal__input-box--padding {
  width: 90%;
  height: 30px;
  color: #7c7c80;
  padding: 0 10px;
  outline: none;
}

.create-qr-modal__date-input {
  display: flex;
}

.create-qr-modal__date-input-box {
  width: 100%;
  height: 30px;
  padding: 0 10px;
  color: #7c7c80;
  outline: none;
  cursor: default;
}

.create-qr-modal__date-icon {
  color: #7c7c80;
  margin-right: 15px;
}

.create-qr-modal__footer {
  display: flex;
  margin-top: 15px;
  justify-content: space-between !important;
  border-top: none !important;
}

.create-qr-modal__footer-btn {
  display: flex;
  justify-content: center;
  height: 40px;
  width: 80px;
  padding: 0 5px;
  align-items: center;
  background-color: #7c7c80;
  border-radius: 3px;
  color: #ffffff;
  cursor: pointer;
}

.create-qr-modal__footer-btn--margin {
  display: flex;
  justify-content: center;
  height: 40px;
  width: 80px;
  padding: 0 5px;
  margin-right: 15px;
  align-items: center;
  background-color: #7c7c80;
  border-radius: 3px;
  color: #ffffff;
  cursor: pointer;
}

.create-qr-modal__footer-btn-grp {
  display: flex;
}

.create-qr-modal__footer-btn--danger {
  display: flex;
  justify-content: center;
  height: 40px;
  width: 80px;
  padding: 0 5px;
  margin-right: 15px;
  align-items: center;
  background-color: #dc3545;
  border-radius: 3px;
  color: #ffffff;
  cursor: pointer;
}

.create-qr-modal__footer-btn--success {
  display: flex;
  justify-content: center;
  height: 40px;
  width: 80px;
  padding: 0 5px;
  align-items: center;
  background-color: #28a745;
  border-radius: 3px;
  color: #ffffff;
  cursor: pointer;
}

.create-qr-modal__select {
  width: 100%;
  outline: none;
  padding: 0 10px;
  height: 30px;
}

.create-qr-modal__detail-table {
  font-size: 14px;
  color: #7c7c80;
}

.create-qr-modal__confirmation-table {
  font-size: 14px;
  color: #7c7c80;
  min-width: 50%;
}

.create-qr-modal__confirmation-notice {
  font-size: 14px;
  color: #7c7c80;
  font-style: italic;
}

.create-qr-modal__confirmation-dialog {
  z-index: 1051 !important;
  background: rgba(0, 0, 0, 0.5);
  color: #7c7c80;
}

.create-qr-modal__confirmation-dialog-title {
  font-size: 20px;
  margin-bottom: 5px;
  color: #9B1ACC;
}

/* React Date Picker */
.create-qr-modal__date-picker-day {
  outline: none !important;
}

.create-qr-modal__date-picker-day.react-datepicker__day--selected {
  background-color: #9B1ACC;
}

.create-qr-modal__date-picker-day.react-datepicker__day--selected:hover {
  background-color: #E1BAF0;
}

.create-qr-modal__date-picker-day.react-datepicker__day--in-selecting-range {
  background-color: #E1BAF0 !important;
}

.create-qr-modal__date-picker-day.react-datepicker__day--in-range {
  background-color: #9B1ACC;
}

.create-qr-modal__date-picker-day.react-datepicker__day--in-range:hover {
  background-color: #E1BAF0;
}

.create-qr-modal__date-picker-day.react-datepicker__day--keyboard-selected {
  background-color: #9B1ACC;
}

.create-qr-modal__date-picker-day.react-datepicker__day--keyboard-selected:hover {
  background-color: #E1BAF0;
}

.create-qr-modal__time-picker.react-datepicker__time-list-item--selected {
  background-color: #9B1ACC !important;
}
/* QR Page */
.qr-page__container {
  display: flex;
  flex-direction: column;
  padding: 40px;
  background-color: #F6F8FF;
}

.qr-page__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 28px;
  color: #9B1ACC;
  margin-bottom: 20px;
}

.qr-page__add-qr {
  display: flex;
  background-color: #9B1ACC;
  color: #ffffff;
  border-radius: 3px;
  align-items: center;
  height: 36px;
  padding: 0 14px;
  font-size: 16px;
  cursor: pointer;
}

.qr-page__add-icon {
  margin-right: 10px;
}

.qr-page__table-controls {
  display: flex;
  justify-content: space-between;
  color: #7c7c80;
}

.qr-page__table-filters {
  display: flex;
  justify-content: space-evenly;
  -webkit-user-select: none;
          user-select: none;
}

.qr-page__table-filter {
  margin-right: 50px;
  align-items: center;
  font-size: 18px;
}

.qr-page__table-filter--selected {
  margin-right: 50px;
  align-items: center;
  font-size: 18px;
  color: #9B1ACC;
  border-bottom: 3px solid #9B1ACC;
  padding-bottom: 3px;
}

.qr-page__table-controls-section {
  display: flex;
  align-items: center;
}

.qr-page__search-input {
  padding: 0 10px;
  color: #7c7c80;
}

.qr-page__search-input:focus {
  outline: none;
}

.qr-page__table-body {
  display: flex;
  margin-top: 20px;
}

.qr-page__table-view-icon {
  color: #7c7c80;
}

.qr-page__table-view-icon:hover {
 color: #9B1ACC;
}

.table__body {

}

.qr-page__table-body > 
.table__wrapper >
.table__container >
.table__body >
.table__row {
  cursor: default;
}

.qr-page__table-body > 
.table__wrapper >
.table__container >
.table__body >
.table__row:hover {
  background-color: #E1BAF0 !important;
}
/* vendor Page */
.vendor-page__container {
  display: flex;
  flex-direction: column;
  padding: 40px;
  background-color: #f6f8ff;
}

.vendor-page__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 28px;
  color: #9b1acc;
  margin-bottom: 20px;
}

.vendor-page__add-vendor {
  display: flex;
  background-color: #9b1acc;
  color: #ffffff;
  border-radius: 3px;
  align-items: center;
  height: 36px;
  padding: 0 14px;
  font-size: 16px;
  cursor: pointer;
}

.vendor-page__add-icon {
  margin-right: 10px;
}
.vendor-page__actions-container {
  display: flex;
  margin-left: -10px;
  justify-content: center;
  grid-gap: 20px;
  gap: 20px;
}

.vendor-page__table-controls {
  display: flex;
  justify-content: space-between;
  color: #7c7c80;
}

.vendor-page__table-filters {
  display: flex;
  justify-content: space-evenly;
  -webkit-user-select: none;
          user-select: none;
}

.vendor-page__table-filter {
  margin-right: 50px;
  align-items: center;
  font-size: 18px;
}

.vendor-page__table-filter--selected {
  margin-right: 50px;
  align-items: center;
  font-size: 18px;
  color: #9b1acc;
  border-bottom: 3px solid #9b1acc;
  padding-bottom: 3px;
}

.vendor-page__table-controls-section {
  display: flex;
  align-items: center;
}

.vendor-page__search-input {
  padding: 0 10px;
  color: #7c7c80;
}

.vendor-page__search-input:focus {
  outline: none;
}

.vendor-page__table-body {
  display: flex;
  margin-top: 20px;
}

.vendor-page__table-view-icon {
  color: #7c7c80;
}

.vendor-page__table-view-icon:hover {
  color: #9b1acc;
}

.vendor-page__table-body
  > .table__wrapper
  > .table__container
  > .table__body
  > .table__row {
  cursor: default;
}

.vendor-page__table-body
  > .table__wrapper
  > .table__container
  > .table__body
  > .table__row:hover {
  background-color: #e1baf0 !important;
}

/* Finance Dashboard */

.finance-dashboard__container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  padding: 60px 0 0 80px;
  background-color: #F6F8FF;
}
.payplus-back--btn {
  color: #007bff;
  cursor: default;
}

.payplus-back--btn:hover {
  text-decoration: underline;
}
.roundborder {
    margin: auto;
    color: #007bff;
    border: 1px solid #007bff;
    border-radius: 50%;
    padding: 9px;
    width: 45px;
    height: 45px;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.hidescroll::-webkit-scrollbar {
    display: none;
}
  
  /* Hide scrollbar for IE, Edge and Firefox */
.hidescroll {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.button-list-container {
  overflow-x: auto;
}

.button-list {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  padding: 10px;
}
@media (max-width: 991.98px) {
  .card-holder {
    min-width: 18rem;
    width: 100%;
    margin-bottom: 1rem;
  }
}
@media (min-width: 991.98px) {
  .card-holder {
    border-right: 1px solid #dee2e6;
    padding: 0 1rem;
  }
}

.selectable-slot:hover {
  background-color: lightgrey !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.banner-fill {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.banner-fill img {
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%;
}

.banner-image {
  /* background: url("./img/banner/banner-02.jpg"); */
  height: 110px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50% 50%;
  background-color: #c2d69b;
}

.carousel-padding {
  padding-left: 35px;
  padding-right: 35px;
}

.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 35px; /* change this value to required width */
  text-align: center;
  filter: invert(60%);
  opacity: 0.5;
}

.carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 35px; /* change this value to required width */
  text-align: center;
  filter: invert(60%);
  opacity: 0.5;
}

.xlargefontsize {
  font-size: 30px !important;
}

.largefontsize {
  font-size: 24px !important;
}

.defaultfontsize {
  font-size: 20px !important;
}
.biggerfontsize {
  font-size: 18px !important;
}
.normalsmallfontsize {
  font-size: 16px !important;
}
.mediumfontsize {
  font-size: 14px !important;
}
.defaultstandardfontsize {
  font-size: 12px !important;
}
.defaultsmallfontsize {
  font-size: 10px !important;
}
.xsmallfontsize {
  font-size: 5px !important;
}

.normal-youtube-icon {
  font-size: 16px !important;
  color: red;
}

.youtubecontainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.eventnote {
  font-size: 20px !important;
  margin-top: 6px;
  margin-right: 5px;
}

.accountcircle {
  font-size: 20px !important;
  margin-top: 6px;
}
#redcircledot {
  background: #f00;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-top: 5px;
  opacity: 80%;
}
.lineentry {
  text-overflow: ellipsis;
  line-clamp: 2;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.twolineentry {
  text-overflow: ellipsis;
  line-clamp: 2;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.cardzoom {
  transition: transform 0.2s; /* Animation */
}

.cardzoom:hover {
  transform: scale(
    1.01
  ); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.cardhighlight {
  background-color: #fff;
}

.cardhighlight:hover {
  background-color: #fafafa;
}

.cardhighlight:hover #viewbutton {
  background-color: #dc3545;
  color: #fff;
}

.darkgreencolor {
  color: #458e3b;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.announcementpanel,
.prayerpanel {
  border: 1px solid #dddddd;
  background-color: #fff;
  color: #444444;
}
.announcementpanelheader,
.prayerpanelheader {
  color: #000000;
}

.announcementpaneldetails,
.prayerpaneldetails {
  color: #444444;
}

.announcementpanelblue,
.prayerpanelblue {
  border: 1px solid #eaf2fd;
  background-color: #eaf2fd;
  border: 1px solid #dddddd;
  color: #444444;
}

.announcementpanelheaderblue,
.prayerpanelheaderblue {
  color: #277af5;
}

.darkbluecolor {
  color: #004085;
}

.lightframecolor {
  border: 1px solid #dfe1e4;
}

.greyframecolor {
  border: 1px solid #ced4da;
}

.lightredframecolor {
  border: 1px solid #f5c2c7;
}

.whiteframecolor {
  border: 1px solid #fff;
}

.lightgreygreenframecolor {
  border: 1px solid #d3e5d0;
}

.redframecolor {
  border: 1px solid #d93d3f;
  background: #f8d7da;
}

.lightfilledredframecolor {
  border: 1px solid #f5c2c7;
  background: #f8d7da;
}

.greenframecolor {
  border: 1px solid #51a847;
}

.greenscanindicator {
  border-top: 3px solid #fff;
}

.whitescanindicator {
  border-top: 3px solid #fff;
}

.mycatholicsgred {
  color: #d93d3f;
}

.mycatholicsgblack {
  color: #222;
}

.mycatholicsgbgred {
  background-color: #d93d3f;
}

.mycatholicsggreen {
  color: #3d811e;
}

.iconcircle {
  background-color: #fff;
  /* border: 1px solid red; */
  height: 30px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  width: 30px;
}

.text-black {
  color: #000;
}
.showvideowrapper {
  width: 100%;
  overflow: hidden;
  margin-top: 0px;
  margin-bottom: 20px;
  display: block;
  height: 200px;
}

.clickablebar {
  align-items: center;
  cursor: pointer;
}

.attendancecounter {
  display: flex;
  align-items: center;
  padding: 10px;
  font-size: 2em;
  color: green;
  cursor: pointer;
}

.hidevideowrapper {
  width: 100%;
  overflow: hidden;
  margin-top: 0px;
  margin-bottom: 0px;
  display: block;
  height: 30px;
}

.showvideoarea {
  width: 100%;
  overflow: hidden;
  margin-top: 0px;
  margin-bottom: 0px;
  display: block;
  /* height: 260px; */
  margin-right: 0px;
  margin-left: 0px;
  padding-right: 0px;
  padding-left: 0px;
}

.hidevideoarea {
  width: 100%;
  overflow: hidden;
  margin-top: 0px;
  margin-bottom: 0px;
  display: none;
  height: 0px;
}

.dotlegend {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;
}

.roundcorner {
  border-radius: 8px;
  border: 1px solid #fff;
}

.roundcorneralertprimary {
  border-radius: 8px;
  border: 1px solid #cde5ff;
  color: #004085;
}
.roundtopcorners {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.roundcornernocolor {
  border-radius: 8px;
}

.ovalcornernocolor {
  border-radius: 15px;
}

.bigovalcornernocolor {
  border-radius: 20px;
}

.image-upload > input {
  visibility: hidden;
  width: 0;
  height: 0;
  display: none;
}

.image-upload {
  margin-top: -48px;
  margin-left: 140px;
  width: 50px;
}

.image-upload-icon {
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.noHover {
  pointer-events: none;
}

.pointer {
  cursor: pointer;
}

.pointerhover {
  cursor: pointer;
}
.pointerhover:before {
  content: " ";
  padding-left: "5px";
}

.pointerhover > span:hover {
  cursor: pointer;
  text-decoration: underline;
}

.dangerunderline {
  text-decoration: underline;
}

.itemlist {
  color: #444;
  text-decoration: underline;
}

.itemlist:hover {
  text-decoration: underline;
  color: #dc3545;
}

.toplevelmenu {
  color: black;
}

.toplevelmenu:hover {
  color: #dc3545;
  text-decoration: underline;
}

.iconnormal {
  display: block;
  transform: rotate(0deg);
  transition: all 1s ease-in;
  -moz-transition: background 0.5s linear; /* Firefox 4 */
  -webkit-transition: background 0.5s linear; /* Safari and Chrome */
  -o-transition: background 0.5s linear; /* Opera */
  -ms-transition: background 0.5s linear; /* Explorer 10 */
}

.iconrotatesmooth {
  display: block;
  transform: rotate(90deg);
  transition: all 1s ease-in;
  -moz-transition: background 0.5s linear; /* Firefox 4 */
  -webkit-transition: background 0.5s linear; /* Safari and Chrome */
  -o-transition: background 0.5s linear; /* Opera */
  -ms-transition: background 0.5s linear; /* Explorer 10 */
}

.iconrotate {
  transform: rotate(90deg);
}

.iconrotate45 {
  transform: rotate(45deg);
}

.bigclock {
  font-size: 24px;
  color: green;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.alignvertical {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.dropdownwidthsm {
  max-width: 140px;
}
.dropdownwidthmd {
  max-width: 240px;
}
.dropdownwidthlg {
  max-width: 280px;
}

.dropdown-menu {
  min-width: 1rem;
}
.dropdown,
.dropup {
  position: relative;
}
.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  content: "";
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #d93d3f;
  font-weight: bold;
}

.nav-link {
  color: #444;
}
.nav-link:hover {
  color: #d93d3f;
}
.react-bootstrap-table table {
  table-layout: auto;
}

.forcehide {
  height: 0px;
  overflow: hidden;
}

.red-bordered-circle {
  color: #3d811e;
  border: 3px solid #3d811e;
  background-color: #fff;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  width: 30px;
  align-items: center;
  display: flex;
  justify-content: space-around;
  font-weight: bold;
}

.black-bordered-circle {
  color: #444;
  border: 3px solid #444;
  background-color: #fff;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  width: 30px;
  align-items: center;
  display: flex;
  justify-content: space-around;
  font-weight: bold;
}

.center-content {
  float: left;
  align-items: center;
  display: flex;
  justify-content: space-around;
}

#bottomcenter {
  height: 50px;
  position: fixed;
  bottom: 10%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  opacity: 1;
}

.adjusticon {
  margin-top: -3px;
}

.seemore,
.seemore:hover {
  padding-top: 0px;
  padding-bottom: 4px;
  margin-bottom: 10px;
  color: #444;
  font-weight: 600;
  float: right;
}

.seemorelight,
.seemorelight:hover {
  padding-top: 0px;
  padding-bottom: 4px;
  margin-bottom: 10px;
  color: #000;
  float: right;
}

.seemoreunderline,
.seemoreunderline:hover {
  padding-top: 0px;
  padding-bottom: 4px;
  margin-bottom: 10px;
  /* color: #000; */
  text-decoration: underline;
  float: right;
}

.bordertopbottom {
  border-top: 8px solid #eee;
  border-bottom: 8px solid #eee;
}

.graypanel {
  background-color: #f7fafb;
}

.darkgraypanel {
  background-color: #f0f2f6;
}

.silhouette {
  filter: sepia(1) saturate(20) hue-rotate(180deg);
  /* filter: contrast(0) sepia(1) saturate(6) brightness(0.05) hue-rotate(80deg); */
  /* filter: contrast(100) hue-rotate(180deg); */
}

.lightspinner {
  color: #dadada;
}

.nophotoiconcolor {
  color: #aaa;
}

.wordbreakall {
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.uen:hover {
  text-decoration: underline;
}

.notextdeco:hover {
  text-decoration: none !important;
}

.notextdeco {
  text-decoration: none !important;
}

.cardentry:hover {
  text-decoration: none !important;
  background-color: #f0f2f6;
}

.cardentry {
  border-radius: 8px;
  text-decoration: none !important;
}

.custom-control-lg .custom-control-label::before,
.custom-control-lg .custom-control-label::after {
  top: 0.1rem !important;
  left: -2rem !important;
  width: 1.4rem !important;
  height: 1.4rem !important;
}

.scanheaderitemwidth {
  min-width: 130px;
}

.horizontalloader {
  animation-duration: 2s;
  -webkit-user-select: none;
          user-select: none;
}

.yt-loader {
  animation-name: horizontalProgressBar;
  animation-timing-function: ease;
  background: #ef534e;
  height: 6px;
  left: 0;
  top: 0;
  width: 0%;
  z-index: 0;
  position: relative;
}

.yt-loader:after {
  display: block;
  position: absolute;
  content: "";
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: #ef534e 1px 0 2px 1px;
  opacity: 0.1;
}

@keyframes horizontalProgressBar {
  0% {
    width: 0%;
  }
  30% {
    width: 50%;
  }
  40% {
    width: 60%;
  }
  80% {
    width: 85%;
  }
  100% {
    width: 100%;
  }
}

.react-datepicker__navigation--next {
  margin-top: 5px;
}
.react-datepicker__navigation--previous {
  margin-top: 5px;
}

/* Font Imports */

@font-face {
  font-family: "kollektif";
  src: url(/static/media/Kollektif.94911052.woff) format("woff");
}

@font-face {
  font-family: "providence-sans";
  src: url(/static/media/ProvidenceSans.57783ae7.woff) format("woff");
}

.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
}

.modal-dialog-xlg {
  width: 90% !important;
  max-width: none !important;
}

