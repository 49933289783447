/* Hide scrollbar for Chrome, Safari and Opera */
.hidescroll::-webkit-scrollbar {
    display: none;
}
  
  /* Hide scrollbar for IE, Edge and Firefox */
.hidescroll {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.button-list-container {
  overflow-x: auto;
}

.button-list {
  display: flex;
  gap: 10px;
  padding: 10px;
}