.floating-input-field {
  display: flex;
  width: 100%;
  position: relative;
}

.floating-input-field--full {
  width: 100%;
}
.floating-input-field > span, input, textarea, select{
  transition: all 0.2s;
  touch-action: manipulation;
}
.floating-input-field > input:focus,
.floating-input-field > textarea:focus {
  outline: 0;
}

.floating-input-field > input:placeholder-shown + span,
.floating-input-field > textarea:placeholder-shown + span {
  opacity: 0;
  position: absolute;
  top: -15px;
  padding: 0 5px;
  color: transparent;
  cursor: text;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transform: translate(0, 0.5rem) scale(0.75);
}

.floating-input-field > input:focus::placeholder,
.floating-input-field > textarea:focus::placeholder{
  color: transparent;
}

.floating-input-field > input:not(:placeholder-shown) + span,
.floating-input-field > input:focus + span,
.floating-input-field > textarea:not(:placeholder-shown) + span,
.floating-input-field > textarea:focus + span {
  opacity: 1;
  position: absolute;
  top: -20px;
  padding: 0 5px;
  color: #828282;
  transform: translate(0, 0.5rem) scale(0.75);
  background-color: #FFFFFF;
  cursor: pointer;
}

.floating-input-field input {
  width: 100%;
  height: 55px;
  padding-left: 15px;
  border: 2px solid #D0D3D7;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.floating-input-field input:focus {
  outline: none;
}

.floating-input-field input::placeholder {
  font-size: 14px;
  color: #828282;
}
.floating-input-field span,
.floating-input-field > span, input, textarea, select,
.floating-input-field > .port-setup-jobId__container > span, input {
  transition: all 0.2s;
  touch-action: manipulation;
}
.floating-input-field span {
  position: absolute;
  top: -20px;
  padding: 0 5px;
  color: #828282;
  cursor: text;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: #FFFFFF;
  font-size:1rem;
  transform: translate(0, 0.5rem) scale(0.75);
}