/* Table */
.table__wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;

  &.sticky {

    .table__header-container,
    .footer {
      position: sticky;
      z-index: 1;
    }

    .table__header {
      top: 0;
    }

    .footer {
      bottom: 0;
    }

    .body {
      position: relative;
      z-index: 0;
    }

    [data-sticky-td] {
      position: sticky !important;
    }
  }
}

.table__container {
  display: flex;
  flex-direction: column;
  height: 60vh;
  background-color: #ffffff;
  width: 100%;
  overflow-x: scroll;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.25);
}

.table__header-container {
  display: flex;
  height: 50px;
  align-items: center;
  border-radius: 5px 5px 0 0;
  color: #7c7c80;
  background-color: #ffffff;

  position: sticky;
  top: 0;
  z-index: 2;
  /* width: 100%; */
}

.table__header {
  display: flex;
  height: 100%;
  padding: 10px 0px 10px 20px;
  user-select: none;
  position: relative;
  justify-content: space-between;
  border-bottom: 1px solid #e5e5e5;
  background-color: #ffffff;
}

.table__header:last-child>.resizer {
  display: none;
}

.table__header-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.resizer {
  right: 0;
  width: 4px;
  height: 35px;
  margin-right: 8px;
  position: absolute;
  touch-action: none;
}

.resizer:hover {
  background: #7c7c80;
}

.resizer.isResizing {
  background: #9B1ACC;
  cursor: none;
}

.table__body-no-results {
  display: flex;
  justify-content: center;
  margin-top: 100px;
  color: #7c7c80;
}

.table__data {
  display: flex;
  position: relative;
  font-size: 14px;
  height: 50px;
  background-color: #ffffff;
  align-items: center;
  padding: 0 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-bottom: 1px solid #e5e5e5;
}

.table__data:last-child {
  display: flex;
  position: relative;
  font-size: 14px;
  height: 50px;
  background-color: #ffffff;
  align-items: center;
  padding: 0 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.table__pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  padding: 0 10px;
  user-select: none;
}

.table__pagination-direction {
  display: flex;
  width: 80px;
  color: #7c7c80;
  justify-content: space-between;
}

.table__pagination-btn--disabled {
  color: #c7c7c7;
}

.table__pagination-count {
  margin: 0 40px;
  color: #7c7c80;
}

.table__pagination-select {
  outline: none;
  border: none;
  background: none;
  margin-left: 10px;
  color: #7c7c80;
}

.table__pagination-size {
  display: flex;
  color: #7c7c80;
}