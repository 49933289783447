/* Finance Dashboard Top Nav */

.finance-topnav__container {
  display: flex;
  width: 100%;
  padding: 8px 16px;
  justify-content: space-between !important;
  background-color: #ffffff;
  height: 60px;
  border-bottom: 1px solid #efefef;
  box-shadow: 1px 1px 4px #dfdfdf;
  cursor: default;
}

.finance-topnav__section-container {
  display: flex;
  width: 33%;
}

.finance-topnav__section--center {
  justify-content: center;
}

.finance-topnav__section--right {
  justify-content: flex-end;
}

.finance-topnav__logo {
  height: 30px;
  width: 30px;
  align-self: center;
  margin-right: 5px;
}

.finance-topnav__logo-text {
  margin-top: 3px;
}