/* vendor Page */
.vendor-page__container {
  display: flex;
  flex-direction: column;
  padding: 40px;
  background-color: #f6f8ff;
}

.vendor-page__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 28px;
  color: #9b1acc;
  margin-bottom: 20px;
}

.vendor-page__add-vendor {
  display: flex;
  background-color: #9b1acc;
  color: #ffffff;
  border-radius: 3px;
  align-items: center;
  height: 36px;
  padding: 0 14px;
  font-size: 16px;
  cursor: pointer;
}

.vendor-page__add-icon {
  margin-right: 10px;
}
.vendor-page__actions-container {
  display: flex;
  margin-left: -10px;
  justify-content: center;
  gap: 20px;
}

.vendor-page__table-controls {
  display: flex;
  justify-content: space-between;
  color: #7c7c80;
}

.vendor-page__table-filters {
  display: flex;
  justify-content: space-evenly;
  user-select: none;
}

.vendor-page__table-filter {
  margin-right: 50px;
  align-items: center;
  font-size: 18px;
}

.vendor-page__table-filter--selected {
  margin-right: 50px;
  align-items: center;
  font-size: 18px;
  color: #9b1acc;
  border-bottom: 3px solid #9b1acc;
  padding-bottom: 3px;
}

.vendor-page__table-controls-section {
  display: flex;
  align-items: center;
}

.vendor-page__search-input {
  padding: 0 10px;
  color: #7c7c80;
}

.vendor-page__search-input:focus {
  outline: none;
}

.vendor-page__table-body {
  display: flex;
  margin-top: 20px;
}

.vendor-page__table-view-icon {
  color: #7c7c80;
}

.vendor-page__table-view-icon:hover {
  color: #9b1acc;
}

.vendor-page__table-body
  > .table__wrapper
  > .table__container
  > .table__body
  > .table__row {
  cursor: default;
}

.vendor-page__table-body
  > .table__wrapper
  > .table__container
  > .table__body
  > .table__row:hover {
  background-color: #e1baf0 !important;
}
