.pearls {
  /* display: block; */
  margin: 0 0 22px;
}
.pearl {
  position: relative;
  padding: 0;
  margin: 0;
  text-align: center;
}
.pearl:after,
.pearl:before {
  position: absolute;
  top: 18px;
  z-index: 0;
  width: 50%;
  height: 4px;
  content: "";
  background-color: #eee;
}
.pearl:before {
  left: 0;
}
.pearl:after {
  right: 0;
}
.pearl:first-child:before,
.pearl:last-child:after {
  display: none !important;
}
.pearl-icon,
.pearl-number {
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 36px;
  height: 36px;
  line-height: 32px;
  color: #fff;
  text-align: center;
  background: #bdbdbd;
  border: 2px solid #bdbdbd;
  border-radius: 50%;
}
.pearl-number {
  font-size: 18px;
}
.pearl-icon {
  font-size: 18px;
}
.pearl-title {
  display: block;
  margin-top: 0.5em;
  margin-bottom: 0;
  overflow: hidden;
  font-size: 16px;
  color: #616161;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.pearl.current:after,
.pearl.current:before {
  background-color: #3d811e;
}
.pearl.current .pearl-icon,
.pearl.current .pearl-number {
  color: #3d811e;
  background-color: #fff;
  border-color: #3d811e;
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  -o-transform: scale(1.3);
  transform: scale(1.3);
}
.pearl.disabled {
  pointer-events: none;
  cursor: auto;
}
.pearl.disabled:after,
.pearl.disabled:before {
  background-color: #eee;
}
.pearl.disabled .pearl-icon,
.pearl.disabled .pearl-number {
  color: #fff;
  background-color: #bdbdbd;
  border-color: #bdbdbd;
}
.pearl.error:before {
  background-color: #3f51b5;
}
.pearl.error:after {
  background-color: #eee;
}
.pearl.error .pearl-icon,
.pearl.error .pearl-number {
  color: #f44336;
  background-color: #fff;
  border-color: #f44336;
}
.pearl.done:after,
.pearl.done:before {
  background-color: #3d811e;
}
.pearl.done .pearl-icon,
.pearl.done .pearl-number {
  color: #fff;
  background-color: #3d811e;
  border-color: #3d811e;
}
.pearls-lg .pearl:after,
.pearls-lg .pearl:before {
  top: 20px;
}
.pearls-lg .pearl-title {
  font-size: 18px;
}
.pearls-lg .pearl-icon,
.pearls-lg .pearl-number {
  width: 40px;
  height: 40px;
  line-height: 36px;
}
.pearls-lg .pearl-icon {
  font-size: 20px;
}
.pearls-lg .pearl-number {
  font-size: 20px;
}
.pearls-sm .pearl:after,
.pearls-sm .pearl:before {
  top: 16px;
}
.pearls-sm .pearl-title {
  font-size: 14px;
}
.pearls-sm .pearl-icon,
.pearls-sm .pearl-number {
  width: 32px;
  height: 32px;
  line-height: 28px;
}
.pearls-sm .pearl-number {
  font-size: 16px;
}
.pearls-sm .pearl-icon {
  font-size: 14px;
}
.pearls-xs .pearl:after,
.pearls-xs .pearl:before {
  top: 12px;
  height: 2px;
}
.pearls-xs .pearl-title {
  font-size: 12px;
}
.pearls-xs .pearl-icon,
.pearls-xs .pearl-number {
  width: 24px;
  height: 24px;
  line-height: 20px;
}
.pearls-xs .pearl-number {
  font-size: 12px;
}
.pearls-xs .pearl-icon {
  font-size: 12px;
}

.adjustcheckicon {
  margin-top: -2px;
}
