/* QR Page */
.qr-page__container {
  display: flex;
  flex-direction: column;
  padding: 40px;
  background-color: #F6F8FF;
}

.qr-page__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 28px;
  color: #9B1ACC;
  margin-bottom: 20px;
}

.qr-page__add-qr {
  display: flex;
  background-color: #9B1ACC;
  color: #ffffff;
  border-radius: 3px;
  align-items: center;
  height: 36px;
  padding: 0 14px;
  font-size: 16px;
  cursor: pointer;
}

.qr-page__add-icon {
  margin-right: 10px;
}

.qr-page__table-controls {
  display: flex;
  justify-content: space-between;
  color: #7c7c80;
}

.qr-page__table-filters {
  display: flex;
  justify-content: space-evenly;
  user-select: none;
}

.qr-page__table-filter {
  margin-right: 50px;
  align-items: center;
  font-size: 18px;
}

.qr-page__table-filter--selected {
  margin-right: 50px;
  align-items: center;
  font-size: 18px;
  color: #9B1ACC;
  border-bottom: 3px solid #9B1ACC;
  padding-bottom: 3px;
}

.qr-page__table-controls-section {
  display: flex;
  align-items: center;
}

.qr-page__search-input {
  padding: 0 10px;
  color: #7c7c80;
}

.qr-page__search-input:focus {
  outline: none;
}

.qr-page__table-body {
  display: flex;
  margin-top: 20px;
}

.qr-page__table-view-icon {
  color: #7c7c80;
}

.qr-page__table-view-icon:hover {
 color: #9B1ACC;
}

.table__body {

}

.qr-page__table-body > 
.table__wrapper >
.table__container >
.table__body >
.table__row {
  cursor: default;
}

.qr-page__table-body > 
.table__wrapper >
.table__container >
.table__body >
.table__row:hover {
  background-color: #E1BAF0 !important;
}