@import url("https://fonts.googleapis.com/css2?family=Inter+Tight:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.graph-tooltip {
  background-color: #f8f9fa !important;
  border: 1px solid rgb(108, 117, 125);
}

.overview-statistic-row-contaier {
  .card {
    height: 100%;
  }

  .card-body {
    background-color: rgba(0, 0, 0, 0.03);
    margin: 0px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

#waiverTooltip>.tooltip-inner {
  color: black;
  background-color: white;
  border: 2px solid #FFC107;
}

#waiverTooltip>.arrow::before {
  border-top-color: #FFC107;
}
